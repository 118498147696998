// @flow
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Image, Layout, Link, withFrontMatter } from '../../components';
import type { FrontMatter, ImageType } from '../../utils/types';

const Box = styled.span.attrs({
  children: '---',
})``;

// $FlowFixMe styled-components-v4
const InvisibleBox = styled(Box)`
  visibility: hidden;
`;

const GreenBox = styled(Box)`
  color: #080;
  background-color: #080;
`;

const RedBox = styled(Box)`
  color: #a00;
  background-color: #a00;
`;

const BlueBox = styled(Box)`
  color: #00c;
  background-color: #00c;
`;

const YellowBox = styled(Box)`
  color: rgb(248, 245, 129);
  background-color: rgb(248, 245, 129);
`;

const BrownBox = styled(Box)`
  color: rgb(223, 211, 157);
  background-color: rgb(223, 211, 157);
`;

const GrayBox = styled(Box)`
  color: rgb(190, 205, 218);
  background-color: rgb(190, 205, 218);
`;

const PushPin = styled(Box)`
  color: rgb(0, 238, 0);
  background-color: rgb(0, 238, 0);
  border: 2px solid black;
  border-radius: 20px;
`;

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
    _17: ImageType,
    _18: ImageType,
    _19: ImageType,
    _20: ImageType,
    _21: ImageType,
    _22: ImageType,
    _23: ImageType,
    _24: ImageType,
    _25: ImageType,
    _26: ImageType,
    _27: ImageType,
    _28: ImageType,
    _29: ImageType,
    _30: ImageType,
    _31: ImageType,
    _32: ImageType,
    _33: ImageType,
    _34: ImageType,
    _35: ImageType,
  },
};

class Blog_2017_12_05_Canyons_No_Longer_Within_Grand_Staircase_Escalante_National_Monument extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          The following canyons will no longer be located within the Grand
          Staircase-Escalante National Monument.
        </p>
        <p>
          This is speculation from the{' '}
          <Link to="https://wilderness.org/sites/default/files/Grand%20Staircase-Escalante%20National%20Monument%20-%20Boundary%20Modification.pdf">
            recently leaked draft
          </Link>{' '}
          of the modifications.
        </p>
        <ul>
          <li>All Egypts</li>
          <li>Peek-a-Boo, Spooky, Dry Fork</li>
          <li>Brimstone, Little, Sandslide, BFS, SOS, Scorpion</li>
          <li>Catstair</li>
          <li>
            Possibly Lick Wash, Deer Creek, Bull Valley Gorge, Willis Creek
            Narrows, Round Valley Draw
          </li>
        </ul>
        <p>
          All of these canyons are still in Wilderness Study Areas. WSAs require
          an act of congress to revoke the designation and no new construction
          or mechanized vehicles are allowed.
        </p>
        <p>
          For a more thorough look at what is in and out of the monument, see
          this{' '}
          <Link to="http://www.sltrib.com/news/2017/12/09/what-will-the-reduction-of-grand-staircase-escalante-national-monument-mean-for-popular-hiking-trails-and-sites/">
            excellent article
          </Link>{' '}
          in <Link to="http://www.sltrib.com/">The Salt Lake Tribune</Link>.
        </p>
        <hr />
        <p>
          I lined up the leaked draft image map with the current (former?)
          border and added all the Escalante canyons from the{' '}
          <Link to="https://amazingslots.blogspot.com/2017/07/the-super-amazing-canyoneering-map.html">
            Super Amazing Canyoneering Map
          </Link>
          .
        </p>
        <ul>
          <li>
            <InvisibleBox /> <b>Thin Black Line</b> - Original border (same with
            the sharp red line)
          </li>
          <li>
            <GreenBox /> Green Diagonal Lines - Escalante Canyons National
            Monument
          </li>
          <li>
            <RedBox /> Red Diagonal Lines - Kaiparowits National Monument
          </li>
          <li>
            <BlueBox /> Blue Diagonal Lines - Grand Staircase National Monument
          </li>
          <li>
            <YellowBox /> BLM Wilderness Study
          </li>
          <li>
            <BrownBox /> Bureau of Land Management
          </li>
          <li>
            <GrayBox /> National Park Service
          </li>
          <li>
            <PushPin /> Green Pushpins - Many of the known slot canyons in the
            area
          </li>
        </ul>
        <p>
          In the image below, it looks like the southern border of Escalante
          Canyons National Monument is Egypt Road so all of the Egypt canyons
          and anything over there that drains into 25 Mile Wash is no longer
          part of any national monument.
        </p>
        <p>
          Note that Fence Canyon and all canyons on the opposite side of the
          Escalante River are in Glen Canyon National Recreation Area and were
          never part of GSENM.
        </p>
        <Image
          caption="Draft Escalante Canyons National Monument"
          image={data._1}
        />
        <p>
          All the canyons south of Egypt road on Hole-in-the-Rock Road are no
          longer within a national monument. This includes Spooky and Peek-a-Boo
          and many other canyons that drain into Coyote Gulch.
        </p>
        <p>
          I also find it unusual that nearly all of Hole-in-the-Rock Road was
          removed from the monument.
        </p>
        <Image caption="Hole-in-the-Rock Road" image={data._2} />
        <p>
          I’m not quite sure about which of these slot canyons are within a
          national monument still. Catstair Canyon that is north of Buckskin
          Gulch and The Wave is definitely out now.
        </p>
        <Image
          caption="Draft Grand Staircase National Monument"
          image={data._3}
        />
        <p>
          <Link to="https://www.amazon.com/clouddrive/share/KvGYA2miWawLQT8BzGTZzH8cBqdgsWUQz0u0Tir8JUu">
            Download Google Earth KMZ file (16MB)
          </Link>{' '}
          where I got these images from.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter(
  '2017-12-05-canyons-no-longer-within-grand-staircase-escalante-national-monument'
)(
  Blog_2017_12_05_Canyons_No_Longer_Within_Grand_Staircase_Escalante_National_Monument
);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2017-12-05-canyons-no-longer-within-grand-staircase-escalante-national-monument/1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2017-12-05-canyons-no-longer-within-grand-staircase-escalante-national-monument/2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    _3: file(
      relativePath: {
        eq: "images/blog/2017-12-05-canyons-no-longer-within-grand-staircase-escalante-national-monument/3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
